import Link from 'next/link'
import CoverImage from 'library/atoms/CoverImage'
import widgetRoutes from 'enums/routes'
import PurchasableCardPrice from 'library/molecules/PurchasableCardPrice'
import { Text, H4 } from 'library/atoms/Typography'
import Item, { ItemText, ItemBottom } from 'library/molecules/Item'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import getRentalDisplayPrice from 'helpers/getRentalDisplayPrice'
import {
  SchedulableCardProps,
  StyledTagsList,
  StyledDivider,
  StyledTag
} from 'library/molecules/SchedulableCard'
import LocationTag from 'library/molecules/LocationTag'

const RentalCard = ({
  schedulable,
  outlet,
  externalHref,
  onClickLocationTag,
  tags,
  labels,
  bgColor,
  labelTextColor,
  ...props
}: SchedulableCardProps) => {
  const { t } = useTranslation('chargeable')
  const { query } = useRouter()
  const widgetSlug = query.widgetSlug

  /**
   * Only render the price if the data has been fetched. This card is also used on the
   * https://eola.co/search page and the price shouldn't be showed on this page.
   * */
  const hasPrice = schedulable?.resource_requirements?.some(
    req => req.price_pence
  )

  return (
    <Link
      key={schedulable.id}
      href={
        externalHref ??
        widgetRoutes.RENTAL.url({
          widgetSlug: widgetSlug as string,
          rentalSlug: schedulable.slug as string
        })
      }
      passHref
      legacyBehavior
    >
      <Item
        variant="card"
        title={t('common:view-item-name', {
          itemName: schedulable.name()
        })}
        target={externalHref ? '_blank' : undefined}
        {...props}
      >
        <CoverImage
          height={250}
          src={schedulable.imageURL('fullscreen')}
          top={
            labels &&
            labels.map(label => (
              <StyledTag
                variant="icon"
                key={label.title}
                bgColor={bgColor}
                labelTextColor={labelTextColor}
              >
                <Text fontStyle="p3">{label.text}</Text>
              </StyledTag>
            ))
          }
        />
        <ItemText>
          <H4>{schedulable.name()}</H4>
        </ItemText>
        {tags && (
          <ItemText>
            <StyledTagsList>
              {tags
                .flatMap(label => {
                  return [
                    <StyledDivider key={label + '--divider'} />,
                    <Text
                      size="sm"
                      fontstyle="p3"
                      color="secondary"
                      key={label}
                    >
                      {label}
                    </Text>
                  ]
                })
                .slice(1)}
            </StyledTagsList>
          </ItemText>
        )}
        <ItemBottom>
          {hasPrice && (
            <ItemText>
              <PurchasableCardPrice
                pence={getRentalDisplayPrice({
                  minimumDuration: schedulable?.minimum_duration,
                  durationMins: schedulable?.duration_mins,
                  lowestResourceReqPrice: schedulable.lowestResourceReqPrice()
                })}
                currency={outlet.currency()}
              />
            </ItemText>
          )}
          {schedulable?.addressSummaryLine() && (
            <LocationTag
              text={schedulable.addressSummaryLine()}
              onClick={onClickLocationTag}
            />
          )}
        </ItemBottom>
      </Item>
    </Link>
  )
}

export default RentalCard
